import React,{ useEffect } from 'react';

const SocialMediaList = ({
	_key,
	title,
	items = [], // The array of items to display
}) => {
	useEffect(() => {
		const script = document.createElement('script');
		script.src = "https://lets.shop/productWidgetSnippet.js?id=destini-locator";
		script.charset = "utf-8";
		script.async = true; // Optional: Makes the script load asynchronously
		document.body.appendChild(script);
	
		// Clean up: remove the script when the component is unmounted
		return () => {
			document.body.removeChild(script);
		};
	}, []);
	return(
	<>
	<section className="custom-landing-page display-flex grid-container flush justify-content-center pr">
	<div className='mamabear full-width display-flex align-items-center justify-content-center'>
		<div className='mb6 page-heading full-width text-center align-items-center justify-content-center'>
			<h3 className='tc fw--800 h1'>#MamaBearApproved</h3>
		</div>
		<div className='row gutter--large--xl grid-container full-width'>
			{/* Map over the items array to generate a card for each item */}
			{items.map((item, index) => (
				<div className='col c4--md' key={index}>
					<div className='mamabear-card'>
						<a href={item.social_media_links || '#'} className="db link--opacity mb1" aria-label={item.name || 'Social Media Item'}>
							<div className="image size--1x1">
								<picture>
									<source
										srcSet={`${item.image.url}?w=2000&auto=format&q=75`}
										media="(min-width: 1000px)"
									/>
									<source
										srcSet={`${item.image.url}?w=1000&auto=format&q=75`}
										media="(min-width: 400px)"
									/>
									<img
										alt={item.image.alt}
										src={`${item.image.url}?w=680&auto=format&q=75`}
									/>
								</picture>
							</div>
						</a>
						<div className="aic mb1 text-center mamabear-details display-flex full-width ">
							 <div className='personal-detail aic jcc display-flex'>
								{/* <picture>
									<img alt={item.influencer || 'Influencer'} src={item.influencerImage || '/images/default-influencer.jpg'} />
								</picture> */}
								<a href={item.social_media_links || '#'}> @{item.name || 'influencer_name'}</a>
							</div> 
							<div className='like-container aic jcc'>
								<a href='#'>
									<svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 21" width="25" height="21">
										<path id="&lt;Path&gt;" className="s0" d="m23.6 5.7c-0.4-2.5-2.5-4.5-5-5-2.7-0.4-5.1 0.9-6.3 3-1.3-2.1-3.7-3.4-6.4-3-2.5 0.5-4.5 2.5-5 5-0.3 2.2 0.5 4.3 2 5.6l7.7 7.7c0.9 0.9 2.4 0.9 3.3 0l7.7-7.7c1.5-1.3 2.3-3.4 2-5.6z" />
									</svg>
								</a>
							</div>
						</div>
					</div>
				</div>
			))}
		</div>
	</div>
	</section>
											{/* Destini Widget */}
		 <div class="grid-container contained mt5 mt11--lg mb11" style={{ marginTop: `50px` }} id="container-destini-box">
			<div class="row align--center">
				<div class="col c10--lg">
					<div APO="857630006878,857630006885" id="destini-locator" class="destini-locator-class" locator-id="4243" alpha-code="1093" locator-name="2024 Shop Genexa" client-id="genexa" destini-header-name="" destini-header-image="">
					</div>
				</div>
			</div>
		</div> 
	</>


);
};

export default SocialMediaList;
