import React from 'react';
import Link from './Link';

const BannerWithCta = ({
	_key,
	desktopBackground,
	mobileBackground,
	cta,
	ctaColor,
	ctaBackgroundColor
}) => {
	const handleScroll = (e) => {
		e.preventDefault(); // Prevent default anchor click behavior
		const target = document.getElementById('container-destini-box');
		if (target) {
			// Calculate target position with a 10% margin from the top of the viewport
			const offsetTop = target.getBoundingClientRect().top + window.scrollY;
			const marginOffset = window.innerHeight * 0.15; // 15% of the viewport height
			
			// Scroll to the target with the margin
			window.scrollTo({
				top: offsetTop - marginOffset, 
				behavior: 'smooth'
			});
		}
	};
	return (
	<section className="custom-landing-page bg--white display-flex grid-container flush justify-content-center pr">
	<div className='hero-container display-flex pr full-width gutter--none align-items-center justify-content-center pr'>
		<div className='db image'>
		<picture>
			<source
				srcSet={`${desktopBackground.url}?w=2000&auto=format&q=75`}
				media="(min-width: 681px)"
			/>
			<source
				srcSet={`${mobileBackground.url}?w=1000&auto=format&q=75`}
				media="(max-width: 680px)"
			/>
			<img
				alt={desktopBackground.alt}
				src={`${desktopBackground.url}?w=680&auto=format&q=75`}
			/>
		</picture>
		</div>
		<div className='hero-inner-image mt4 mt2--lg mb2--lg full-width pa landing-page-btn align-items-center justify-content-center'>
					<a href='#container-destini-box' className='shop-now-btn btn fw--600' onClick={handleScroll} aria-label="Shop Now" >Shop Now</a>
			{/* <a href='#' className='shop-now-btn btn fw--600'>Shop Now</a> */}
		</div>
	</div>
</section>
)};
export default BannerWithCta;
