import React, { useEffect, useRef, useState } from 'react';

export default function landingPageCompare({
  title,
  sub_title,
  button_text,
  link,
  genexa_image,
  other_brand_image,
  genexa_image_mobile,
  other_brand_image_mobile

}) {

  const [beforeWidth, setBeforeWidth] = useState('50%'); // Dynamic width for before image
  const [afterWidth, setAfterWidth] = useState('50%');   // Dynamic width for after image
  const [showLeftDrag, setShowLeftDrag] = useState(false); // Track left drag visibility
  const [showRightDrag, setShowRightDrag] = useState(false); // Track right drag visibility
  const [handlerVisible, setHandlerVisible] = useState(true); // Track handler button visibility
  const [sliderVisible, setSliderVisible] = useState(true); // Track visibility of the comparison slider
  const [sliderHandlerVisible, setSliderHandlerVisible] = useState(true); // Track visibility of the comparison slider handle

  const [isResizing, setIsResizing] = useState(false);
  const [leftWidth, setLeftWidth] = useState(50); // Initial width as 50% of the container
  const [rightWidth, setRightWidth] = useState(50); // Initial width as 50% for the right div
  const [leftScrollReached, setLeftScrollReached] = useState(false);
  const [rightScrollReached, setRightScrollReached] = useState(false);
  const [hideLeftContent, setHideLeftContent] = useState(false);
  const [hideRightContent, setHideRightContent] = useState(false);
  const [sliderPosition, setSliderPosition] = useState(50); // For mobile slider
  const [isDragging, setIsDragging] = useState(false); // For mobile slider
  const [activeImage, setActiveImage] = useState(null); // Tracks which image is currently active

  const leftDivRef = useRef(null);
  const rightDivRef = useRef(null);
  const containerRef = useRef(null);
  const resizeHandleRef = useRef(null);
  const [isMobileSliderVisible, setIsMobileSliderVisible] = useState(true);

  //Hover Logic
  const handleLeftDivHover = () => {
    setLeftWidth(70); // Expand left div
    setRightWidth(30); // Shrink right div
    setLeftScrollReached(true); // Show afterscroll content in left div
    setHideRightContent(true); // Hide right content
  };

  const handleRightDivHover = () => {
    setRightWidth(70); // Expand right div
    setLeftWidth(30); // Shrink left div
    setRightScrollReached(true); // Show afterscroll content in right div
    setHideLeftContent(true); // Hide left content
  };

  const handleMouseLeave = () => {
    setLeftWidth(50); // Reset left div width
    setRightWidth(50); // Reset right div width
    setLeftScrollReached(false); // Hide afterscroll in left div
    setRightScrollReached(false); // Hide afterscroll in right div
    setHideLeftContent(false); // Show left content
    setHideRightContent(false); // Show right content
  };

  // Desktop slider logic
  useEffect(() => {
    const leftDiv = leftDivRef.current;
    const resizeHandle = resizeHandleRef.current;
  
    let initialX = 0;
    let initialLeftWidth = 0;
  
    const resizeDiv = (e) => {
      if (isResizing) {
        const deltaX = e.clientX - initialX; // Mouse position change
        const containerWidth = leftDiv.parentNode.offsetWidth; // Get container width
        const newLeftWidthPercentage = ((initialLeftWidth + deltaX) / containerWidth) * 100;
    
        // Ensure left div width stays between 30% and 70%
        if (newLeftWidthPercentage >= 30 && newLeftWidthPercentage <= 70) {
          setLeftWidth(newLeftWidthPercentage);
          setRightWidth(100 - newLeftWidthPercentage);
    
          // Hide right content when left div is dragged more than 60%
          if (newLeftWidthPercentage >= 60) {
            setHideRightContent(true); // Hide right content
          } else {
            setHideRightContent(false); // Show right content
          }
    
          // Hide left content when right div is dragged more than 60%
          if (100 - newLeftWidthPercentage >= 60) {
            setHideLeftContent(true); // Hide left content
          } else {
            setHideLeftContent(false); // Show left content
          }
    
          // Show `afterscrolldiv` when left div reaches 60% to 70%
          if (newLeftWidthPercentage >= 60) {
            setLeftScrollReached(true); // Show left `afterscrolldiv`
          } else {
            setLeftScrollReached(false); // Hide left `afterscrolldiv`
          }
    
          // Show `afterscrolldiv` when right div reaches 60% to 70%
          if (100 - newLeftWidthPercentage >= 60) {
            setRightScrollReached(true); // Show right `afterscrolldiv`
          } else {
            setRightScrollReached(false); // Hide right `afterscrolldiv`
          }
        }
      }
    };
    
    
  
    const stopResizing = () => {
      setIsResizing(false);
      document.removeEventListener('mousemove', resizeDiv);
      document.removeEventListener('mouseup', stopResizing);
    };
  
    const handleMouseDown = (e) => {
      setIsResizing(true);
      initialX = e.clientX;
      initialLeftWidth = leftDiv.offsetWidth;
  
      document.addEventListener('mousemove', resizeDiv);
      document.addEventListener('mouseup', stopResizing);
    };
  
    resizeHandle.addEventListener('mousedown', handleMouseDown);
  
    return () => {
      resizeHandle.removeEventListener('mousedown', handleMouseDown);
      document.removeEventListener('mousemove', resizeDiv);
      document.removeEventListener('mouseup', stopResizing);
    };
  }, [isResizing]);
  
  
  
  // Mobile slider logic
  const handleBeforeClick = () => {
    // Set 'before' as active if it's not already active
    if (activeImage !== 'before') {
      setShowLeftDrag(true);
      setShowRightDrag(false);
      setBeforeWidth('95%');
      setAfterWidth('5%');
      setHandlerVisible(false); // Hide handler button
      setSliderHandlerVisible(false); // Hide comparison slider handle
      setActiveImage('before'); // Set active image to before
    }
  };

  const handleAfterClick = () => {
    // Set 'after' as active if it's not already active
    if (activeImage !== 'after') {
      setShowRightDrag(true);
      setShowLeftDrag(false);
      setAfterWidth('95%');
      setBeforeWidth('5%');
      setHandlerVisible(false); // Hide handler button
      setSliderHandlerVisible(false); // Hide comparison slider handle
      setActiveImage('after'); // Set active image to after
    }
  };

  return (
    <section className="custom-landing-page display-flex grid-container flush justify-content-center pr">
			<div className="full-width gradient-bg product-draggable">
        <div className="mb6 page-heading grid-container contained text-center align-items-center justify-content-center">
          <h3 className="tc h3">{title}</h3>
          <h2 className="tc h2">{sub_title}</h2>
        </div>
        {/* Desktop Slider */}
        <div className="draggable-slider df for-desktop">
          <div className="outer df full-width">
            <div id="container" style={{ display: 'flex', width: '100%' }}>
            <div
                ref={leftDivRef}
                id="leftDiv"
                className="leftDiv df aic jcc"
                style={{ width: `calc(${leftWidth}% - 0px)`, flexShrink: 0, transition: 'width 0.5s ease' }}
                onMouseEnter={handleLeftDivHover}
                onMouseLeave={handleMouseLeave}
              >
                <div className="scrolling-div slider-content left-content df">
                  <div className={`only-content df ${hideLeftContent ? 'hide' : ''}`}>
                    <div className={`beforescroll ${leftScrollReached ? 'hide' : ''}`}>
                      <h2>LEADING<span> BRANDS</span></h2>
                      <h3>UP TO</h3>
                      <h5>90<span>% </span></h5>
                      <h6>UNWANTED <span>FILLERS</span></h6>
                    </div>
                    <div className={`afterscrolldiv ${leftScrollReached ? '' : 'hide'}`}>
                      <h2>LEADING<span> BRANDS</span></h2>
                      <h4>Competitors use the same effective medicine, but they also may have:</h4>
                      <ul>
                        <li><span></span>Artificial Preservatives</li>
                        <li><span></span> Artificial Sweeteners</li>
                        <li><span></span> Artificial Dyes</li>
                      </ul>
                    </div>
                  </div>
                  <div className="med-div med-div-left">
                    <picture>
                      <img alt={other_brand_image.alt} src={`${other_brand_image.url}`} style={{ maxWidth: '100%' }} />
                    </picture>
                  </div>
                </div>
              </div>
              <div ref={resizeHandleRef} className="resizeHandle" style={{ cursor: 'col-resize', width: '0px' }}>
                <img alt="handle-img" src="/images/vs.png" />
                <div className="handler-btn">
                <a href={`${link}`} className="btn" aria-label={button_text}>{button_text}</a>
                </div>
              </div>
              <div ref={rightDivRef} id="rightDiv" className="rightDiv df aic jcc"
                style={{ width: `calc(${rightWidth}% - 0px)`, flexShrink: 0, transition: 'width 0.5s ease' }}
                onMouseEnter={handleRightDivHover}
                onMouseLeave={handleMouseLeave}
              >
                <div className="scrolling-div slider-content right-content df">
                  <div className="med-div med-div-right">
                    <picture>
                      <img alt={genexa_image.alt} src={`${genexa_image.url}`} style={{ maxWidth: '100%' }} />
                      
                    </picture>
                  </div>
                  <div className={`only-content df ${hideRightContent ? 'hide' : ''}`}>
                    <div className={`beforescroll ${rightScrollReached ? 'hide' : ''}`}>
                        <a href='#' className='logo-class'>
                          <picture>
                            <img alt="logo" src='/images/slider-logo.png' />
                          </picture>
                        </a>
                      <h3>ALWAYS</h3>
                      <h5>0<span>%</span></h5>
                      <h6>ARTIFICIAL <span>ADDITIVES</span></h6>
                    </div>
                    <div className={`afterscrolldiv ${rightScrollReached ? '' : 'hide'}`}>
                        <a href='#' className='logo-class'>
                          <picture>
                            <img alt="logo" src='/images/slider-logo.png' />
                          </picture>
                        </a>
                      <h4>Genexa has the same effective medicine, and has:
                      </h4>
                      <ul>
                        <li><span></span>0% artificial preservatives,
                        flavors, and sweeteners </li>
                        <li><span></span>0% Dyes and Common Allergens </li>
                        <li >AND, we’re made in the USA with
                      Non-GMO globally sourced ingredients.</li>
                      </ul>
                      

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* For Mobile Slider */}
        {isMobileSliderVisible && (
        <div className="draggable-slider for-mobile df mobile-slider">
          <div className="outer full-width">
            <div className="comparison-container df aic jcc" ref={containerRef}>
              
              {/* Before Image Section */}
              <div 
                className={`comparison-image before ${activeImage === 'before' ? 'active-before' : activeImage ? 'inactive' : ''}`} // Add active/inactive classes
                style={{ width: beforeWidth }} 
                onClick={handleBeforeClick} // Handle click event
                onFocus={handleBeforeClick} // Handle focus event
                role="button" // Make it accessible
                tabIndex={0} // Make it focusable
                ref={leftDivRef}
              > 
                <div className='mobile-slider-content mobile-left-content slider-content'>
                  <div className='df slider-in-content left-slider-in aic'>
                    {activeImage !== 'before' && activeImage !== null && ( // Conditionally render the left arrow icon
                      <div className='arrow-icon left-arrow'>
                        <a href='javascript:void(0)' className=''>
                          <img alt='right arrow' src='/images/right-arrow.png' />
                        </a>
                      </div>
                    )}
                    <div className='inactive-block aic'>
                      <div className={`before-drag`}>
                        <h2>LEADING<span> BRANDS</span></h2>
                        <h3>UP TO</h3>
                        <h5>90<span>% </span></h5>
                        <h6>UNWANTED <span>FILLERS</span> </h6>
                      </div>
                      <div className='product-img'>
                        <picture>		
                          <img alt={other_brand_image_mobile.alt} src={`${other_brand_image_mobile.url}`}/>
                        </picture>
                      </div>
                    </div>
                  </div>
                </div>                                
              </div>
              
              {/* After Image Section */}
              <div 
                className={`comparison-image after ${activeImage === 'after' ? 'active-after' : activeImage ? 'inactive' : ''}`} // Add active/inactive classes
                style={{ width: afterWidth }} 
                onClick={handleAfterClick} // Handle click event
                onFocus={handleAfterClick} // Handle focus event
                role="button" // Make it accessible
                tabIndex={0} // Make it focusable
                ref={rightDivRef}
              >
                <div className='mobile-slider-content mobile-left-content slider-content'>
                  <div className='df slider-in-content right-slider-in aic'>
                    {activeImage !== 'after' && activeImage !== null && ( // Conditionally render the right arrow icon
                      <div className='arrow-icon right-arrow df'>
                        <a href='javascript:void(0)'>
                          <img alt='left arrow' src='/images/left-arrow.png' />
                        </a>
                      </div>
                    )}
                    <div className='inactive-block aic'>
                      <div className='product-img'>
                        <picture>		
                          <img alt={genexa_image_mobile.alt} src={`${genexa_image_mobile.url}`}/>
                        </picture>
                      </div>
                      <div className={`before-drag`}>
                        <a href='#' className='logo-class'>
                          <picture>
                            <img alt="logo" src='/images/slider-logo.png' />
                          </picture>
                        </a>
                        <h3>ALWAYS</h3>
                        <h5 className='jcc'>0<span>%</span></h5>
                        <h6>ARTIFICIAL <span>ADDITIVES</span></h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
              {/* Slider Handle */}
              {sliderHandlerVisible && ( // Only show slider handle if it's visible
                <div 
                  className="comparison-slider" 
                  style={{ left: `50%` }} // Static as per requirements
                >
                  <div className="slider-handle"></div>
                </div>
              )}
            </div>

            {/* Static Content Below the Slider */}
            <div className='df jcc aic content-ofslider mobile-slider-content mobile-left-content slider-content'>
              {handlerVisible && ( // Conditionally render handler button
                <div className="handler-btn">
                  <a href={`${link}`} className="btn" aria-label={button_text}>{button_text}</a>
                  <a href='javascript:void(0);'>
                    <picture>
                      <img alt="logo" src='/images/swipe-image.png' />
                    </picture>
                  </a>
                </div>
              )}
              <div className={`after-drag left-drag ${showLeftDrag ? '' : 'hidden'}`}>
                <h4>Competitors use the same effective medicine, but they also may have:​</h4>
                <ul>
                  <li><span></span>Artificial Preservatives</li>
                  <li><span></span>Artificial Sweeteners</li>
                  <li><span></span> Artificial Dyes</li>
                </ul>
              </div>
              <div className={`after-drag right-drag ${showRightDrag ? '' : 'hidden'}`}>
                <h4>Genexa has the same effective medicine, and has:​</h4>
                <ul>
                  <li><span></span>0% artificial preservatives, flavors, and sweeteners</li>
                  <li><span></span>0% Dyes and Common Allergens </li>
                  <li><span></span> Artificial Dyes</li>
                </ul>
                <p>AND, we’re made in the USA with Non-GMO globally sourced ingredients.</p>
              </div>
            </div>
          </div>
        </div>
        )}
      </div>
    </section>
  );
}
